import React, { useEffect, useMemo, useState } from 'react';

import { withRouter } from 'react-router-dom';

import * as palette from '../../../../components/General/Variables';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../components/common/Header';
import { getTimeslot } from '../../services/session';
import { getFullProfile } from '../../../../services/api/eureka';
import Auth from '../../../../services/api/auth';
import { Wrapper } from '../../styles';
import { goBackFromVirtualSession } from '../common/goBack';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../../../AccessRestrictions/utils';
import AccessDeniedModal from '../../../AccessRestrictions/AccessDeniedModal';
import Iframe from 'react-iframe';
import { getLocalAppStateAsync } from '../../../../services/api/db';

const OnDemandMetaFusionSession = props => {
    const { authUser, match, history, location } = props;
    const { timeslotId } = match.params;
    const [timeslot, setTimeslot] = useState({});
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const [restrictedUser, setRestrictedUser] = useState(false);
    const [appState, setAppState] = useState({});
    const isExternal = timeslot?.params?.onDemandRecType === 'External';

    const onDemandData = useMemo(() => {
        if (isExternal) {
            const queryString = timeslot?.params?.onDemandExternalLink.split('?')[1];
            const queryParams = queryString.split('&').reduce((acc, param) => {
                const [key, value] = param.split('=');
                acc[key] = decodeURIComponent(value);
                return acc;
            }, {});
            const metaFusionClientId = queryParams['metaFusionClientId'];
            const metaFusionEnabled = queryParams['metaFusionEnabled'] === 'true';
            const metaFusionProjectId = queryParams['metaFusionProjectId'];
            const onDemandMetaFusionId = queryParams['onDemandMetaFusionId'];
            return {
                metaFusionClientId,
                metaFusionEnabled,
                metaFusionProjectId,
                onDemandMetaFusionId,
            };
        }

        return {
            onDemandMetaFusionId: timeslot?.params?.onDemandMetaFusionId,
            metaFusionProjectId: timeslot?.params?.metaFusionProjectId,
            metaFusionClientId: appState?.metaFusionClientId,
            metaFusionEnabled: appState?.metaFusionEnabled,
        };
    }, [timeslot, isExternal, appState]);

    const goBackFunction = () => goBackFromVirtualSession(match, history, location, timeslotId);

    useEffect(() => {
        if (location.state && location.state.keepHistoryLength) {
            history.replace(location.pathname, {
                ...location.state,
                keepHistoryLength: false,
            });
            sessionStorage.setItem(timeslotId + '_history_index', JSON.stringify(history.length));
        }
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            setTimeslot(timeslot);

            if (Auth.isUserAuthenticated()) {
                const fullProfile = await getFullProfile();
                const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
                    timeslot?.typeParams?.virtualRoomRestrictedGroup,
                    fullProfile.userGroups,
                );
                setRestrictedUser(!canUserAccessSession);
            }
        });
    }, [timeslotId]);

    useEffect(() => {
        getLocalAppStateAsync().then(val => {
            setAppState(val);
        });
    }, []);

    useEffect(() => {
        if (isExternal && typeof window?.iFrameResize === 'function') {
            // eslint-disable-next-line no-unused-expressions
            window?.iFrameResize({ checkOrigin: false }, '.meta-widget-ondemandplayer');
        }
    }, [isExternal]);

    const goBack = () => {
        return goBackFunction();
    };

    if (restrictedUser) {
        return <AccessDeniedModal onClose={() => history.push('/')} />;
    }

    const url = `https://${onDemandData.metaFusionClientId}.meta-dcr.com/${onDemandData.metaFusionProjectId}/widgets/ondemandplayer/?cr=${onDemandData.onDemandMetaFusionId}&embedmode=fill`;
    return (
        <React.Fragment>
            {authUser && (
                <Wrapper mobile={mobile}>
                    <Header goBack={goBack} timeslot={timeslot} authUser={authUser} />
                    {!isExternal ? (
                        <div style={{ paddingTop: 56, width: '100%' }}>
                            <Iframe
                                title="MetaFusion"
                                className="meta-widget-ondemandplayer"
                                frameBorder="0"
                                scrolling="no"
                                allow="autoplay"
                                allowFullScreen
                                class="meta-widget-ondemandplayer"
                                frameborder="0"
                                allowfullscreen
                                styles={{ width: '100%', height: 'calc(100vh - 56px)' }}
                                url={url}
                                checkOrigin={false}
                            />
                        </div>
                    ) : (
                        <div style={{ paddingTop: 56 }}>
                            <meta-player
                                id={onDemandData.onDemandMetaFusionId}
                                slug={onDemandData.onDemandMetaFusionId}
                                aiFeatures={true}
                                subtitleFeatures={true}
                                transcriptSearchFeature={true}
                                transcriptDownloadFeature={true}
                                transcriptLanguages={[
                                    { language: 'German', isoCode: 'de', value: true },
                                    { language: 'Dutch', isoCode: 'nl', value: true },
                                    { language: 'English', isoCode: 'en', value: true },
                                ]}
                            />
                        </div>
                    )}
                </Wrapper>
            )}
        </React.Fragment>
    );
};

const ConsumerWrapper = props => {
    return (
        <AuthUserContext.Consumer>
            {authUser => <OnDemandMetaFusionSession {...props} authUser={authUser} />}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(ConsumerWrapper);
