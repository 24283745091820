import React, { createContext, useState } from 'react';
import moment from 'moment';
import { getLocalAppStateAsync, getSettings, updateSettings } from '../../../services/api/db';
import { getUserSettings, updateUserSettings } from '../../../services/api/eureka';
import Auth from '../../../services/api/auth';

const TimezoneContext = createContext();

const TimezoneContextProvider = ({ children }) => {
    const [introDialogVisible, setIntroDialogVisible] = useState(false);
    const [selectDialogVisible, setSelectDialogVisible] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState('local');
    const [eventInfo, setEventInfo] = useState({});

    async function initTimezoneContext(eventId) {
        const settings = await getSettings(eventId);
        const appState = await getLocalAppStateAsync();
        if (appState.timezoneSelection !== 'choice') {
            setSelectedTimezone(appState.timezoneSelection);
        }
        if (
            !settings ||
            settings.useLocalTimezone === null ||
            settings.useLocalTimezone === undefined
        ) {
            await updateSettings(eventId, { useLocalTimezone: true });
            if (appState.timezoneSelection === 'choice') {
                setIntroDialogVisible(true);
            }
        } else {
            const zoneSetting = settings.useLocalTimezone ? 'local' : 'event';
            setSelectedTimezone(zoneSetting);
        }
        setEventInfo({
            eventId: appState.eventId,
            eventTitle: appState.eventName,
            timezone: appState.timezone,
            hasStudioReleases: appState.hasStudioReleases,
        });

        if (Auth.isUserAuthenticated()) {
            await setTimezoneFromEurekaSettings(appState.eventId);
        }
    }

    async function setTimezoneFromEurekaSettings(id) {
        const eventId = id || eventInfo.eventId;
        const response = await getUserSettings(eventId);
        await updateSettings(eventId, { useLocalTimezone: response.useLocalTimezone === true });
        const zoneSetting = response.useLocalTimezone ? 'local' : 'event';
        if (zoneSetting === 'event') {
            setIntroDialogVisible(false);
        }
        setSelectedTimezone(zoneSetting);
        return response.useLocalTimezone === true;
    }

    function showTimezoneSelectDialog() {
        setSelectDialogVisible(true);
    }

    async function getTimezoneSettings() {
        const eventId = eventInfo.eventId;
        const settings = await getSettings(eventId);
        const zoneSetting = settings.useLocalTimezone ? 'local' : 'event';
        setSelectedTimezone(zoneSetting);
        return zoneSetting;
    }

    async function updateTimezoneSettings(zoneSetting) {
        const eventId = eventInfo.eventId;
        const useLocalTimezone = zoneSetting === 'local';
        await updateSettings(eventId, { useLocalTimezone });
        setSelectedTimezone(zoneSetting);
        //update user settings
        if (Auth.isUserAuthenticated()) {
            const userSettings = await getUserSettings(eventInfo.eventId);
            const settings = {
                useLocalTimezone,
                visible: userSettings.visible,
                available: userSettings.available,
                ...eventInfo,
            };

            await updateUserSettings(settings);
        }
    }

    const getLocalTimezoneName = () => {
        const tzOffset = moment().utcOffset();
        return formatOffset(tzOffset);
    };

    const getEventTimezoneName = () => {
        const tz = eventInfo?.timezone;
        const tzOffset = moment().tz(tz).utcOffset();
        return formatOffset(tzOffset);
    };

    const getEventTimezoneLabel = timezone => {
        if (!timezone) {
            return;
        }
        const now = moment.utc();
        const offset = moment.tz.zone(timezone).utcOffset(now);
        return formatOffset(-offset);
    };

    // This will return something like "UTC-2:30"
    const formatOffset = offset => {
        let formatted = 'UTC';
        if (!offset || offset === 0 || offset === '0') {
            return formatted;
        }
        const offsetAsHours = parseInt(offset / 60, 10);
        const diff = Math.abs(offset % 60);
        const operator = offsetAsHours < 0 ? '' : '+';
        formatted = `${formatted}${operator}${offsetAsHours}`;
        if (diff !== 0) {
            formatted += `:${diff}`;
        }
        return formatted;
    };

    function getTimezoneLabel() {
        return selectedTimezone === 'local' ? getLocalTimezoneName() : getEventTimezoneName();
    }

    // Always pass the correct utc date as string as is stored in db
    function getUtcToSelectedTimezone(
        date,
        format,
        forceUseEventTimezone = false,
        locale,
        skipTimeslotCorrection = false,
    ) {
        const { hasStudioReleases, timezone } = eventInfo;
        let validTimezone = timezone;
        const useLocalTimezone = selectedTimezone === 'local';
        if (!forceUseEventTimezone && useLocalTimezone) {
            validTimezone = moment.tz.guess();
        }
        let dateToDisplay = moment.utc(date).tz(validTimezone);
        if (!hasStudioReleases && !skipTimeslotCorrection) {
            // Transform to the event timezone to correct old data.
            const correctDate = moment.utc(date).tz(timezone, true);
            dateToDisplay = correctDate.tz(validTimezone);
        }
        if (format) {
            return locale
                ? dateToDisplay?.locale(locale).format(format)
                : dateToDisplay?.format(format);
        }
        return dateToDisplay;
    }

    // Always pass the correct utc date as string as is stored in db
    function getUnixToSelectedTimezone(date) {
        const { timezone } = eventInfo;
        let validTimezone = timezone;
        const useLocalTimezone = selectedTimezone === 'local';
        if (useLocalTimezone) {
            validTimezone = moment.tz.guess();
        }
        let dateToDisplay = moment.unix(date).tz(validTimezone);
        return dateToDisplay;
    }

    function getSelectedTimezoneName() {
        const { timezone } = eventInfo;
        const useLocalTimezone = selectedTimezone === 'local';
        if (useLocalTimezone) {
            return moment.tz.guess();
        } else {
            return timezone;
        }
    }

    // Always pass the correct utc date as string as is stored in db
    function getTimeslotUtc(date) {
        const { hasStudioReleases, timezone } = eventInfo;
        if (!hasStudioReleases) {
            // Transform to the event timezone to correct old data.
            const correctDate = moment.utc(date).tz(timezone, true);
            return correctDate.toISOString();
        }

        return date;
    }

    function getHappeningNowNextQuery(offset) {
        const { hasStudioReleases, timezone } = eventInfo;
        const now = moment.utc();

        if (!hasStudioReleases) {
            const correction = moment.tz(timezone).utcOffset();
            now.add(correction, 'minutes');
        }

        let ge = now.toISOString();
        const next = now.clone();
        let le = next.add(offset, 'minutes').toISOString();

        return { le, ge };
    }

    const defaultContext = {
        introDialogVisible,
        setIntroDialogVisible,
        selectDialogVisible,
        setSelectDialogVisible,
        getTimezoneSettings,
        updateTimezoneSettings,
        getLocalTimezoneName,
        getEventTimezoneName,
        getTimezoneLabel,
        showTimezoneSelectDialog,
        selectedTimezone,
        setTimezoneFromEurekaSettings,
        initTimezoneContext,
        getEventTimezoneLabel,
        getUtcToSelectedTimezone,
        getSelectedTimezoneName,
        getUnixToSelectedTimezone,
        eventInfo,
        getTimeslotUtc,
        getHappeningNowNextQuery,
    };

    return <TimezoneContext.Provider value={defaultContext}>{children}</TimezoneContext.Provider>;
};

const WithTimezone = Component => {
    return function Timezone(props) {
        return (
            <TimezoneContext.Consumer>
                {timezone => <Component {...props} timezone={timezone} />}
            </TimezoneContext.Consumer>
        );
    };
};

export { TimezoneContext, TimezoneContextProvider, WithTimezone };
