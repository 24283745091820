import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import ChatFeed from '../../../components/Chat/ChatFeed';
import Message from '../../../components/Chat/Message';
import { formatTime, formatTimeMessageSection } from '../../../services/api/data';

let previousMessageStamp = null;

const Chat = ({
    messages,
    userData,
    groupChat,
    loadPreviousMessages,
    conversation,
    isPresentation,
}) => {
    const [chatMessages, setChatMessages] = useState([]);
    const lastTimestamp = useRef();

    const displayMessageDate = (previousDate, date) => {
        const diffHours = date.diff(previousDate, 'hours');
        if (!previousDate || diffHours > 24) {
            return formatTimeMessageSection(date);
        }
    };

    useEffect(() => {
        if (messages.length) {
            previousMessageStamp = null;
            messages.reverse();

            const parsedMessages = messages.map((msg, index) => {
                const reducedTimestamp = msg.timestamp * 0.001;
                const timestamp = formatTime(reducedTimestamp);
                const currentDate = moment(parseInt(reducedTimestamp));
                const dateText = displayMessageDate(previousMessageStamp, currentDate);
                const previousMessage = messages[index - 1];

                const messageObj = new Message({
                    _id: msg._id || 0,
                    id: isPresentation
                        ? msg.user._id
                        : msg.user._id === userData.id
                        ? 0
                        : msg.user._id, //id of the sender (0 is reserved for "blue bubble")
                    body: msg.text,
                    user: msg.user,
                    nick: msg.nick,
                    big: msg.big,
                    failed: msg.failed,
                    representativeTitle: msg.representativeTitle,
                    item: msg,
                    removedFromUser: !!msg.removedFromUser,
                    removedFromModerator: !!msg.removedFromModerator,
                    _timestamp: msg.timestamp,
                    timestamp,
                    dateMessage: dateText,
                });
                previousMessageStamp = currentDate;
                return messageObj;
            });
            lastTimestamp.current = messages[0].timestamp;
            setChatMessages(parsedMessages);
        }
    }, [messages]);

    // list of message objects
    return (
        <ChatFeed
            messages={chatMessages}
            showSenderName={groupChat}
            chatRoomId={conversation && conversation.jid}
            isPresentation={isPresentation}
            loadPreviousMessages={() => {
                loadPreviousMessages(lastTimestamp.current);
            }}
        />
    );
};

export { Chat };
