import { useCallback, useEffect } from 'react';
import { useGlobalState } from '../../../utils/container';
import { TARGET_LANGUAGES, useTranslationsStore } from '../../../stores/TranslationsStore';
import { getVirtualEventCaptions } from '../../../services/api/eureka';

function transformCaptionToMessageMapObj(inputObject) {
    const { id, text, VirtualEventSessionId, UserId } = inputObject;

    let outputObject = {
        VirtualEventSessionId,
        id: `${id}`, // You can generate a unique ID here
        complete: true,
        participantId: UserId,
        translations: {},
    };

    TARGET_LANGUAGES.forEach(language => {
        outputObject.translations[language.value] = text;
    });

    return outputObject;
}

const useReceiveTranslations = ({ active }) => {
    const { socket, virtualEventSession, timeslot } = useGlobalState();
    const isBroadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const VirtualEventSessionId = virtualEventSession && virtualEventSession.id;
    const showCaptions = virtualEventSession && virtualEventSession.showCaptions;
    const { setMessages } = useTranslationsStore();
    const sessionId = timeslot && timeslot.id;

    const handleUpdateTranslations = useCallback(
        data => {
            if (data.objectId !== sessionId) {
                return;
            }

            setMessages(prevMessages => {
                const newMessages = new Map(prevMessages);
                newMessages.set(data.id, data);
                return newMessages;
            });
        },
        [setMessages, sessionId],
    );

    const handleClearTranslations = useCallback(() => {
        setMessages(new Map());
    }, [setMessages]);

    useEffect(() => {
        return () => {
            handleClearTranslations();
            socket.off('updateTranslations', handleUpdateTranslations);
        };
    }, [handleUpdateTranslations]);

    useEffect(() => {
        if (!socket || !active || !sessionId) {
            return;
        }

        if (!isBroadcasting) {
            socket.off('updateTranslations', handleUpdateTranslations);
            return;
        }

        socket.on('updateTranslations', handleUpdateTranslations);
        socket.on('clearTranslations', handleClearTranslations);

        return () => {
            socket.off('clearTranslations', handleClearTranslations);
        };
    }, [socket, handleUpdateTranslations, active, isBroadcasting]);

    useEffect(() => {
        if (!socket || !sessionId) {
            return;
        }

        getVirtualEventCaptions(VirtualEventSessionId).then(captions => {
            const mapMessages = new Map();
            captions.forEach(c => {
                const message = transformCaptionToMessageMapObj(c);
                mapMessages.set(message.id, message);
            });
            setMessages(mapMessages);
        });

        if (!active) {
            return;
        }

        socket.emit('joinTranslations', { objectId: sessionId });
    }, [sessionId, socket, active, VirtualEventSessionId]);
};

export default useReceiveTranslations;
