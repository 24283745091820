import React, { useState } from 'react';
import styled from 'styled-components';
import ComplexDialog from '../Dialog/ComplexDialog';
import { aboutLinks } from '../../scenes/SignInPage/constants/strings';
import { useLocation } from 'react-router-dom';

const Text = styled.div`
    font-family: Roboto;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
`;

const BoldText = styled.div`
    font-family: Cabin;
    font-size: 17px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
`;

const Anchor = styled.a`
    font-family: Roboto;
    font-size: 15px;
    color: #7934ea;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ListItem = styled.li`
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.87);
`;

const CookieNoticeWrapper = ({ children }) => {
    const { pathname } = useLocation();
    const { search } = useLocation();

    const [userAcceptedCookies, setUserAcceptedCookies] = useState(
        localStorage.getItem('userHasAcceptedCookies'),
    );

    const pathNameIsVoteSession = pathname.includes('/vote-session');
    const isModeEmbed = search.includes('mode=embed');

    const doNotShowCookieNotice = pathNameIsVoteSession || userAcceptedCookies || isModeEmbed;

    const handleAccept = () => {
        localStorage.setItem('userHasAcceptedCookies', 'true');
        setUserAcceptedCookies('true');
    };

    const handleClose = () => {
        window.open(aboutLinks.cookiePolicy, '_blank');
    };

    if (!doNotShowCookieNotice) {
        return (
            <div>
                <ComplexDialog
                    visible
                    size="sm"
                    title="Cookie notice"
                    contentStyle={{ padding: '32px 40px 24px' }}
                    options={[
                        {
                            title: 'Accept',
                            variant: 'contained',
                            type: 'save',
                            color: 'primary',
                            onClick: handleAccept,
                        },
                        {
                            title: 'Learn more',
                            variant: 'contained',
                            type: 'cancel',
                            color: 'inherit',
                            onClick: handleClose,
                        },
                    ]}
                >
                    <Text>
                        We and selected third parties use cookies or similar technologies for
                        technical purposes, and with your consent, for other purposes as described
                        below and in our{' '}
                        <Anchor href={aboutLinks.cookiePolicy} target="_blank">
                            Cookie Policy
                        </Anchor>
                        .
                    </Text>
                    <BoldText style={{ marginTop: 24 }}>
                        We use cookies for the following purposes:
                    </BoldText>
                    <ul>
                        <ListItem>
                            Technical purposes necessary for the operation of the platform
                        </ListItem>
                        <ListItem style={{ marginTop: 8 }}>
                            Analysing activity to improve the user experience
                        </ListItem>
                        <ListItem style={{ marginTop: 8 }}>
                            Sending push notifications relating to event news and messages
                        </ListItem>
                    </ul>
                    <BoldText style={{ marginTop: 24 }}>Give permission</BoldText>
                    <Text style={{ marginTop: 16 }}>
                        In order to make use of this platform, please accept the Cookie Policy.
                    </Text>
                </ComplexDialog>
            </div>
        );
    }

    return <div>{children}</div>;
};

export default CookieNoticeWrapper;
