import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-md';
import {
    PanoramaFishEyeFontIcon,
    RadioButtonCheckedFontIcon,
    CheckBoxFontIcon,
    CheckBoxOutlineBlankFontIcon,
} from '@react-md/material-icons';

import AnalyticsService from '../../../features/analytics/services';
import {
    Container,
    InteractivityCell,
    InteractivityGrid,
    SectionText,
    SectionTitle,
} from './style';
import {
    getVirtualEventPollSetsByEventAndItemIds,
    submitPollAnswer,
} from '../../../services/api/eureka';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import { getString } from '../../../services/api/store';
import { makeItem } from '../../../services/api/data';
import { useGlobalState } from '../../../utils/container';
import Button, { buttonTypes } from '../../common/Button';
import { COLOR_GREY } from '../../../components/General/Variables';

const NewWrapper = styled.div`
    float: left;
    color: #ffffff;
    background-color: ${props => props.background};
    text-align: center;
    font-size: 10px;
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    width: 30px;
    margin-right: 4px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
    height: 56px !important;
    border-radius: 8px;
    cursor: pointer;
    display: flex;

    span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px !important;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
    }

    i {
        margin-top: -2px;
        color: ${props => (props.color ? props.color : '#1fa294')};
    }

    &:hover {
        background: #f4f5f7;
    }
`;

const VoteCard = ({ localAppState, objectId, objectClass, theme }) => {
    const VOTE_TITLE = getString('votingTitle') || 'Vote';
    const NEW_LABEL = getString('voteNewLabel') || 'New';
    const WAITING_FOR_VOTE_TO_START_LABEL = getString(
        'votingWaiting',
        'Waiting for voting to start',
    );
    const VOTE_NOT_ACTIVE_LABEL =
        getString('votingNotActive') || 'No active question at this moment.';
    const SUBMIT_VOTE_LABEL = getString('voteSubmitButton', 'Submit vote');
    const VOTE_SUBMITTED_LABEL = getString('votingSuccess', 'Vote submitted');

    const stateCtx = useGlobalState();

    const { socket } = stateCtx;

    const { trackAchievement } = useAchievementActions();

    const [disabled, setDisabled] = useState(false);
    const [joinedPolls, setJoinedPolls] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [externalObjectId, setExternalObjectId] = useState(null);
    const [poll, setPoll] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [pollOptions, setPollOptions] = useState([]);
    const [newLabel, setNew] = useState(false);
    const [voteActive, setVoteActive] = useState(false);
    const [currentPollVote, setCurrentPollVote] = useState(null);

    // const poll = data && data[0] && data[0].state === 'open' && data[0].Polls.find(p => p.active);
    useEffect(() => {
        makeItem(objectId, objectClass, async (err, objectItem) => {
            if (err) {
                console.log(err);
            }
            if (objectItem && objectItem.id) {
                setItemId(objectItem.id);
                setEventId(localAppState.eventId);
                await loadData(localAppState.eventId, objectItem.id);
            }
        });

        if (socket) {
            socket.on(`pollRefresh_${itemId}`, () => loadData(eventId, itemId));
        }
    }, [objectId, localAppState]);

    const loadData = async (eventId, itemId) => {
        const pollSets = await getVirtualEventPollSetsByEventAndItemIds(eventId, itemId);

        setVoteActive(pollSets && pollSets[0] && pollSets[0].state === 'open');
        if (pollSets && pollSets[0]) {
            const poll = pollSets[0].Polls.find(p => p.active);

            setPoll(poll);
            setExternalObjectId(pollSets[0].ExternalObjectId);

            if (poll) {
                initPollOptions(poll.PollOptions);
            }
        }
    };

    useEffect(() => {
        if (joinedPolls.indexOf(externalObjectId) > -1) {
            return () => {};
        }

        setJoinedPolls([...joinedPolls, externalObjectId]);

        if (socket) {
            socket.emit('joinPolls', { objectId: externalObjectId });

            socket.on('updatePollSet', pollData => {
                if (pollData.action === 'addVote') {
                    // increment pollData.pollOptionId with 1 if we display number of votes
                } else if (pollData.action === 'updatePollSet') {
                    loadData(eventId, itemId);
                }
            });
        }

        return () => {
            if (socket) {
                socket.removeAllListeners('updatePollSet');
            }
        };
    }, [externalObjectId]);

    useEffect(() => {
        isSubmitDisabled();
    }, [poll]);

    useState(() => {});

    const isSubmitDisabled = () => {
        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        const currentPollAnswer =
            poll && answeredPolls.find(answeredPoll => answeredPoll?.pollId === poll.id);
        if (currentPollAnswer) {
            setNew(false);
            setDisabled(true);
            setCurrentPollVote(currentPollAnswer);
            return true;
        }
        setNew(true);
        setDisabled(false);
        return false;
    };

    const initPollOptions = pollOptions => {
        pollOptions = pollOptions
            .sort((o1, o2) => o1.order - o2.order)
            .map(option => ({
                ...option,
                checked: false,
            }))
            .filter(option => !!option.text);

        setPollOptions(pollOptions);
    };

    const submitVote = async () => {
        const checkedOptions = pollOptions.filter(option => option.checked);

        if (!checkedOptions || checkedOptions.length === 0) {
            return;
        }

        const sessionId = objectId;
        checkedOptions.forEach(checkedOption => {
            AnalyticsService.addSample(
                'vote',
                JSON.stringify({
                    text: checkedOption.text,
                    votingQuestionId: poll.id,
                    questionText: poll.title,
                }),
                sessionId,
            );
        });

        trackAchievement(AchievementType.VOTE, sessionId);

        const ids = checkedOptions.map(option => option.id);
        await submitPollAnswer({ pollOptionIds: ids });

        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        answeredPolls.push({ pollId: poll.id, optionIds: ids });
        localStorage.setItem('answeredPolls', JSON.stringify(answeredPolls));
        initPollOptions(pollOptions);
        isSubmitDisabled();

        const { socket } = stateCtx;

        if (socket) {
            socket.emit('pollAnswer', { objectId: itemId });
        }
    };

    const VoteButton = () => {
        let buttonText = disabled ? VOTE_SUBMITTED_LABEL : SUBMIT_VOTE_LABEL;
        if (!voteActive) {
            buttonText = WAITING_FOR_VOTE_TO_START_LABEL;
        }
        if (!voteActive && currentPollVote && currentPollVote.pollId === poll?.id) {
            buttonText = VOTE_SUBMITTED_LABEL;
        }
        return (
            <Button
                type={buttonTypes.GREY_LONG}
                background={theme.primary}
                text={buttonText}
                enabled={!(disabled || !voteActive)}
                onClick={submitVote}
                style={{
                    marginTop: '12px',
                }}
            />
        );
    };

    const onChange = (checked, index) => {
        if ((poll.isMultipleChoice && !checked) || disabled || !voteActive) {
            return;
        }

        if (poll.isMultipleChoice) {
            const optionsClone = [
                ...pollOptions.map(option => ({
                    ...option,
                    checked: false,
                })),
            ];
            optionsClone[index].checked = true;
            setPollOptions(optionsClone);
            return;
        }

        const optionsClone = pollOptions.map((option, i) => {
            if (i === index) {
                return {
                    ...option,
                    checked: !option.checked,
                };
            }
            return {
                ...option,
            };
        });
        setPollOptions(optionsClone);
    };

    if (!poll) {
        return (
            <InteractivityGrid>
                <InteractivityCell size={1} key="q&a">
                    <Container>
                        <SectionTitle secondary={theme.secondary}>{VOTE_TITLE}</SectionTitle>
                        <SectionText margin="12px 0px 0px">{VOTE_NOT_ACTIVE_LABEL}</SectionText>
                    </Container>
                </InteractivityCell>
            </InteractivityGrid>
        );
    }

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <Container>
                    <SectionTitle secondary={theme.secondary}>{VOTE_TITLE}</SectionTitle>
                    <TitleContainer>
                        {newLabel && (
                            <NewWrapper background={theme.contrast}>{NEW_LABEL}</NewWrapper>
                        )}
                        <SectionText>{poll && poll.title}</SectionText>
                    </TitleContainer>
                    {pollOptions &&
                        pollOptions.map((option, index) => (
                            <StyledCheckbox
                                id={`poll-option-${index}`}
                                name={`poll-option-${index}`}
                                key={`poll-option-${index}`}
                                label={option.text}
                                checked={
                                    option.checked ||
                                    option.id === currentPollVote?.optionId ||
                                    (currentPollVote?.optionIds || []).indexOf(option.id) > -1
                                }
                                checkedCheckboxIcon={
                                    poll.isMultipleChoice ? (
                                        <RadioButtonCheckedFontIcon
                                            aria-disabled={!voteActive}
                                            style={
                                                disabled || !voteActive ? { color: COLOR_GREY } : {}
                                            }
                                        />
                                    ) : (
                                        <CheckBoxFontIcon
                                            style={
                                                disabled || !voteActive ? { color: COLOR_GREY } : {}
                                            }
                                            aria-disabled={!voteActive}
                                        />
                                    )
                                }
                                uncheckedCheckboxIcon={
                                    poll.isMultipleChoice ? (
                                        <PanoramaFishEyeFontIcon
                                            aria-disabled={!voteActive}
                                            style={
                                                disabled || !voteActive ? { color: COLOR_GREY } : {}
                                            }
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlankFontIcon
                                            style={
                                                disabled || !voteActive ? { color: COLOR_GREY } : {}
                                            }
                                            aria-disabled={!voteActive}
                                        />
                                    )
                                }
                                onChange={checked => onChange(checked, index)}
                                onClick={checked =>
                                    poll?.isMultipleChoice && onChange(checked, index)
                                }
                                defaultChecked={false}
                                disabled={disabled || !voteActive}
                                color={theme.primary}
                            />
                        ))}
                    {<VoteButton />}
                </Container>
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default withRouter(VoteCard);
