import React from 'react';
import styled from 'styled-components';

import VoteOption from './VoteOption';
import { votesColorScheme } from './constants';
import HowToVoteIcon from '@mui/icons-material/HowToVoteOutlined';
import ProgressBar from './ProgressBar';
import { useGlobalState } from '../../../../utils/container';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { getString } from '../../../../services/api/store';

const VotingPreviewWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 16px;
    ${props =>
        props.isbeamerview &&
        `
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    margin-bottom: 24px;
  `};
`;

const Subtitle = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-bottom: 16px;
    ${props =>
        props.isbeamerview &&
        `
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    margin-bottom: 24px;
  `};
`;

const Img = styled.img`
    height: 100%;
    z-index: 1000;
    border-radius: 8px;
    margin: 0 0 16px 0;
    object-fit: contain;
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 298px;
    margin: 0 0 16px 0;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: ${props => (props.isbeamerview ? '24px 0 ' : '8px 0 ')};
`;

const GridContainer = styled.div`
    display: grid;
    grid-gap: 8px;
`;

const PlaceholderContainer = styled.div`
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.votingactive ? '#1fa294' : '#f1f1f3')};
    padding: ${props => (props.isbeamerview ? '5px 12px' : ' 2.5px 8px')};
    border-radius: 16px;
`;

const PlaceholderText = styled.span`
    color: ${props => (props.votingactive ? '#fff' : 'rgba(0, 0, 0, 0.87)')};
    font-size: ${props => (props.isbeamerview ? 18 : 12)}px;
    font-family: Cabin, sans-serif;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-align: center;
`;

const TotalVotesContainer = styled.div`
    position: absolute;
    right: 16px;
    font-family: Roboto, sans-serif;
    font-size: ${props => (props.isbeamerview ? 28 : 24)}px;
    font-weight: ${props => (props.isbeamerview ? 700 : 500)};
    letter-spacing: 0.86px;
    color: ${props => (props.votingactive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)')};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Bar = styled.div`
    position: absolute;
    top: 0;
    height: 8px;
    background-color: #dedee1;
    width: 100%;
    margin-left: -16px;

    @media (max-width: 767px) {
        margin-left: 0;
    }
`;

const BeamerViewBar = styled(Bar)`
    height: 6px;
    border-radius: 6px;
`;

export const WaitingForVoting = () => {
    return (
        <VotingPreviewWrapper style={{ height: '100vh' }}>
            <Bar />
            <FirstRow isbeamerview={0} style={{ paddingTop: 24 }}>
                <PlaceholderContainer votingactive={0}>
                    <PlaceholderText votingactive={0}>WAIT FOR VOTING TO START...</PlaceholderText>
                </PlaceholderContainer>
            </FirstRow>
        </VotingPreviewWrapper>
    );
};

const VotingInProgress = ({
    pollSet,
    activePoll,
    votingIsActive,
    totalVotes,
    onSubmitVote,
    isBeamerView,
    selectedOptionIds,
    setSelectedOptionIds,
}) => {
    const stateCtx = useGlobalState();
    const isPhone = useMediaQuery('(max-width:767px)');
    const { virtualEventSession } = stateCtx;
    const hideVotesNumber = virtualEventSession?.hideVotesNumber || false;
    const { title, PollOptions, imageUrl } = activePoll;
    const options = PollOptions.filter(option => option.text.length > 0).sort(
        (o1, o2) => o1.order - o2.order,
    );

    return (
        <VotingPreviewWrapper>
            {!votingIsActive && !isBeamerView && <Bar />}
            {!votingIsActive && isBeamerView && <BeamerViewBar />}
            {votingIsActive && (
                <ProgressBar
                    pollSet={pollSet}
                    activePoll={activePoll}
                    activeColor={'#1fa294'}
                    isBeamerView={isBeamerView}
                />
            )}
            <FirstRow isbeamerview={isBeamerView ? 1 : 0}>
                <PlaceholderContainer
                    votingactive={votingIsActive ? 1 : 0}
                    isbeamerview={isBeamerView ? 1 : 0}
                >
                    <PlaceholderText
                        votingactive={votingIsActive ? 1 : 0}
                        isbeamerview={isBeamerView ? 1 : 0}
                    >
                        {votingIsActive ? 'VOTE NOW' : 'WAIT FOR VOTING TO START...'}
                    </PlaceholderText>
                </PlaceholderContainer>
                {!hideVotesNumber && (
                    <TotalVotesContainer
                        votingactive={votingIsActive ? 1 : 0}
                        isbeamerview={isBeamerView ? 1 : 0}
                    >
                        {totalVotes}
                        <HowToVoteIcon
                            style={isBeamerView ? { fontSize: 32, marginLeft: 8 } : {}}
                        />
                    </TotalVotesContainer>
                )}
            </FirstRow>
            <Title isbeamerview={isBeamerView ? 1 : 0}>{title}</Title>
            {!activePoll.isMultipleChoice && (
                <Subtitle isbeamerview={isBeamerView ? 1 : 0}>
                    Select one or multiple options and submit your vote.
                </Subtitle>
            )}
            {imageUrl && (
                <ImageContainer>
                    <Img src={imageUrl} id="voting-preview-image" />
                </ImageContainer>
            )}
            <GridContainer
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(isPhone ? 1 : 2),
                    gridAutoRows: '1fr',
                }}
            >
                {options.map((option, index) => (
                    <VoteOption
                        key={`preview-vote-option-${index}`}
                        label={String.fromCharCode(65 + index)}
                        option={option}
                        placeholder="Option"
                        color={votesColorScheme[index]}
                        votingIsActive={votingIsActive}
                        onSubmitVote={onSubmitVote}
                        isBeamerView={isBeamerView}
                        isMultipleChoice={activePoll.isMultipleChoice}
                        selectedOptionIds={selectedOptionIds}
                        setSelectedOptionIds={setSelectedOptionIds}
                    />
                ))}
            </GridContainer>
            {!activePoll.isMultipleChoice && (
                <Button
                    color="primary"
                    onClick={() => onSubmitVote(selectedOptionIds)}
                    disabled={!votingIsActive || selectedOptionIds.length === 0}
                    style={{
                        width: 138,
                        backgroundColor: '#f0f0f0',
                        color: 'rgba(0, 0, 0, 0.87)',
                        alignSelf: 'center',
                        height: 40,
                        padding: '12px 24px',
                        marginTop: 64,
                        fontFamily: 'Cabin',
                        fontWeight: 700,
                        textTransform: 'none',
                        fontSize: '15px',
                        lineHeight: '24px',
                        opacity: votingIsActive && selectedOptionIds.length > 0 ? 1 : 0.5,
                    }}
                >
                    {getString('voteSubmitButton')}
                </Button>
            )}
        </VotingPreviewWrapper>
    );
};

export default VotingInProgress;
