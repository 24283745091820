import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { EmptyState } from '../components/EmptyState';
import { getString } from '../../../services/api/store';
import { Chat as ChatComponent } from '../components';
import { useGlobalState } from '../../../utils/container';
import AnalyticsService from '../../../features/analytics/services';

import notificationsApi from '../../Notifications/api';
import { notificationTypes } from '../../Notifications/constants';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import TextAreaMessageComponent from '../../../components/TextInput/TextAreaMessageComponent';
import { colorTheme } from '../../VirtualModeration/features/virtualFeature/constants/colors';

const MessagesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 0 48px 0;
`;

const Wrapper = styled.div`
    height: calc(100% - ${props => (props.hostedsession ? '56px' : '0px')});
    position: relative;
    overflow: hidden;
    padding: 0 16px;
`;

const InputContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -16px;
    border-top: 1px solid ${colorTheme.SECONDARY};
`;

const sortedMessages = (failed, messages) => {
    if (!failed || failed.length === 0) {
        return messages;
    }
    const allMessages = [...failed, ...messages];
    return allMessages.sort((m1, m2) => m2.timestamp - m1.timestamp);
};

const MAX_TEXT_SIZE = 250;

const GroupChat = ({
    userData,
    messages,
    onSendMessage,
    conversation,
    loadPreviousMessages,
    isHostedSession,
    isPresentation = false,
}) => {
    const [inputMessage, setInputMessage] = useState('');
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [failedMessages, setFailedMessages] = useState([]);

    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const { trackAchievement } = useAchievementActions();
    const isVirtualSessionChat = window.location.pathname.includes('/virtual-session');

    const TEXT_EMPTY_CHAT_ROOM =
        getString('groupChatNoMessages') || 'No chat messages sent in this room yet.';
    const isFirefox = typeof InstallTrigger !== 'undefined';

    useEffect(() => {
        removeChatNotifications();
    }, []);

    const removeChatNotifications = async () => {
        // Mark current chat messages as read, so remove the notifications for this chat to all users
        await notificationsApi.removeNotifications({
            type: notificationTypes.CHAT,
            params: {
                roomId: `${conversation.roomId}`,
            },
        });
    };

    const renderChatMessages = () => (
        <ChatComponent
            messages={sortedMessages(failedMessages, messages)}
            userData={userData}
            conversation={conversation}
            groupChat
            loadPreviousMessages={loadPreviousMessages}
            isPresentation={isPresentation}
        />
    );

    const sendMessage = async text => {
        const sent = await onSendMessage(text);
        setInputMessage('');
        if (!sent) {
            console.error(`CHAT ERROR - cannot send group message`);
            sendFailMessage(text);
            return;
        }
        const date = new Date().getTime();
        AnalyticsService.addSample(
            'chat',
            text,
            `${conversation.id}_${date}_${conversation.roomId}`,
        );
        trackAchievement(AchievementType.CHAT);

        if (isVirtualSessionChat) {
            trackAchievement(AchievementType.CHAT_VIRTUAL_SESSION, conversation.id);
        }

        conversation.participants.map(user => {
            if (user.id !== userData.id) {
                if (socket) {
                    socket.emit('messageNotification', {
                        chatId: conversation.id,
                        senderId: userData.id,
                        userId: user.id,
                        type: 'chat',
                        roomId: conversation.roomId,
                    });
                }
            }
        });
    };

    const sendFailMessage = text => {
        const date = new Date().getTime() * 1000;
        const user = {
            ...userData,
            _id: userData.id,
        };
        const failedMessage = {
            text,
            user,
            _id: date,
            timestamp: date,
            failed: true,
        };
        setFailedMessages([...failedMessages, failedMessage]);
    };

    const handleChange = value => setInputMessage(value);

    return (
        <Wrapper
            isfirefox={isFirefox ? 1 : 0}
            windowheight={windowHeight}
            hostedsession={isHostedSession ? 1 : 0}
        >
            {messages.length ? (
                <MessagesContainer isfirefox={isFirefox ? 1 : 0}>
                    {renderChatMessages()}
                </MessagesContainer>
            ) : (
                <EmptyState message={TEXT_EMPTY_CHAT_ROOM} />
            )}
            {!isPresentation && (
                <InputContainer hostedsession={isHostedSession ? 1 : 0}>
                    <TextAreaMessageComponent
                        sendMessage={sendMessage}
                        placeholder={getString('talkroomInputHintText', 'Write a message...')}
                        onChange={handleChange}
                        maxChar={MAX_TEXT_SIZE}
                        initialMessage={inputMessage}
                    />
                </InputContainer>
            )}
        </Wrapper>
    );
};

export { GroupChat };
