import * as React from 'react';
import styled from 'styled-components';
import { Avatar, FontIcon } from 'react-md';

import styles from './styles';
import * as palette from '../../General/Variables';
import { useTheme } from '../../Theme/ThemeContext';
import { getString } from '../../../services/api/store';
import { messageBubbleStyleType } from '../constants';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import { useState } from 'react';
import DeleteMessageButton from '../../../scenes/VirtualModeration/features/Talk/components/DeleteMessageButton';
import { connect } from 'react-redux';
import { onSetMessageToDelete } from '../../../scenes/Talk/actions';
import { urlRegex } from '../../../scenes/SignInPage/constants/strings';
import { max } from 'lodash';

const CustomAvatar = styled(Avatar)`
    border: unset;
    cursor: pointer;

    & .md-avatar {
        border: none;
    }

    &.md-avatar--default {
        ${props => props.noborder && 'background: transparent'};
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        margin-top: -3px;
    }
`;

const FailedContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${palette.COLOR_WHITE} !important;
`;

const FailedIcon = styled(FontIcon)`
    margin-right: 3px;
    opacity: 0.8;
    color: inherit !important;
    font-size: 0.825rem !important;
`;

const FailedText = styled.span`
    color: inherit !important;
    font-size: 0.7rem;
    font-style: italic;
    opacity: 0.8;
    text-align: left;
    text-transform: uppercase;
`;

const defaultBubbleStyles = {
    userBubble: {},
    chatbubble: {},
    text: {},
};
const { userBubble, chatbubble, text } = defaultBubbleStyles;
const userBubbleStyle = {
    ...styles.chatbubble,
    ...styles.chatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
};
const friendBubbleStyle = {
    ...styles.chatbubble,
    ...styles.recipientChatbubble,
    ...styles.recipientChatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
    marginLeft: '48px',
};
const friendBubbleStylePositionTop = {
    borderRadius: '20px 20px 20px 8px',
};
const friendBubbleStylePositionMiddle = {
    borderRadius: '8px 20px 20px 8px',
};
const friendBubbleStylePositionBottom = {
    borderRadius: '8px 20px 20px 20px',
};
const myBubbleStylePositionTop = {
    borderRadius: '20px 20px 8px 20px',
};
const myBubbleStylePositionMiddle = {
    borderRadius: '20px 8px 8px 20px',
};
const myBubbleStylePositionBottom = {
    borderRadius: '20px 8px 20px 20px',
};

const systemBubbleStyle = {
    ...styles.systemBubble,
};

const messageDefaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: palette.COLOR_WHITE,
    fontSize: '1rem',
    fontWeight: '400',
    margin: 0,
    overflowWrap: 'anywhere',
};

const defaultTimeStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '0.7rem',
    marginTop: '4px',
    marginBottom: '0px',
    textAlign: 'left',
};

const userTimeStyle = {
    ...defaultTimeStyle,
    color: '#ffffff',
};

const currentUserDeletedMsgStyle = {
    fontSize: '0.8rem',
    color: 'rgba(255, 255, 255, 0.74)',
    fontStyle: 'italic',
};

const otherUserDeletedMsgStyle = {
    fontSize: '0.8rem',
    color: 'rgba(28,28,30,0.54)',
    fontStyle: 'italic',
};

const deletedIconStyle = {
    fontSize: '1rem',
    height: '1rem',
    width: '1rem',
    marginRight: 5,
};

const representativeStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '0.7rem',
    marginTop: '0',
    marginBottom: '0',
    textAlign: 'left',
    textTransform: 'uppercase',
    lineHeight: '1rem',
};

const userAndTimeWrapperStyle = { display: 'flex' };

const getAdditionalBubblePositionStyles = message => {
    const myMessage = message.id === 0;
    switch (message.bubbleStyle) {
        case messageBubbleStyleType.TOP:
            return myMessage ? myBubbleStylePositionTop : friendBubbleStylePositionTop;
        case messageBubbleStyleType.MIDDLE:
            return myMessage ? myBubbleStylePositionMiddle : friendBubbleStylePositionMiddle;
        case messageBubbleStyleType.BOTTOM:
            return myMessage ? myBubbleStylePositionBottom : friendBubbleStylePositionBottom;
        default:
            return {};
    }
};

const ChatBubble = ({
    message,
    showAvatar,
    avatarClick,
    chatRoomId,
    setMessageToDelete,
    isPresentation,
}) => {
    const [messageHovered, setMessageHovered] = useState(null);
    const getChatBubbleStyles = () => {
        const baseStyles =
            message.id === 0
                ? {
                      ...userBubbleStyle,
                      backgroundColor: theme.primary,
                  }
                : friendBubbleStyle;
        const additionalBubblePositionStyles = getAdditionalBubblePositionStyles(message);

        return {
            ...baseStyles,
            ...additionalBubblePositionStyles,
            ...(isPresentation && { maxWidth: '100%' }),
        };
    };

    const failedErrorMessage =
        getString('chatMessageFailedDelivery') || 'The following message couldn’t be sent:';
    const { theme } = useTheme();
    // message.id 0 is reserved for blue
    const chatBubbleStyles = getChatBubbleStyles();
    let messageTimeStyle = message.id === 0 ? userTimeStyle : defaultTimeStyle;
    let messageStyle =
        message.id === 0
            ? messageDefaultStyle
            : {
                  ...messageDefaultStyle,
                  color: palette.COLOR_TEXT,
              };
    if (message.big) {
        messageStyle = { ...messageStyle, fontSize: 32 };
    }
    if (message.removedFromUser) {
        const styleToAdd = message.id === 0 ? currentUserDeletedMsgStyle : otherUserDeletedMsgStyle;
        messageStyle = { ...messageStyle, ...styleToAdd };
    }
    if (isPresentation) {
        messageStyle = { ...messageStyle, fontSize: '1.5rem' };
        messageTimeStyle = { ...messageTimeStyle, fontSize: '1rem' };
    }
    const userNameStyle = { ...messageTimeStyle, marginRight: 4 };
    const { user } = message;
    const displaySenderName = (message.id !== 0 || isPresentation) && user && user.displayName;

    const showMessageAsDeleted = message.removedFromUser;
    const messageText = message?.body || '';
    const messageBodyWithLinks = messageText.replaceAll(
        urlRegex,
        `<a href="$1" target="_blank" rel="noopener noreferrer" style="word-break: break-all; overflow: hidden; white-space: normal; color: ${
            message.id === 0 ? '#fff' : 'inherit'
        }">$1</a>`,
    );

    const messageBody = () => (
        <>
            {message.big && <p />}
            {message.failed && (
                <FailedContainer>
                    <FailedIcon>{'block'}</FailedIcon>
                    <FailedText>{failedErrorMessage}</FailedText>
                </FailedContainer>
            )}
            {!showMessageAsDeleted && (
                <span
                    style={messageStyle}
                    dangerouslySetInnerHTML={{ __html: messageBodyWithLinks }}
                ></span>
            )}
            {showMessageAsDeleted && (
                <p style={{ ...messageStyle, flexDirection: 'row', alignItems: 'center' }}>
                    <DoDisturbAltOutlinedIcon style={deletedIconStyle} />
                    Message deleted
                </p>
            )}
            {message.big && <p />}
            {/*
                Friends messages are shown with their names below message body
                so we have to wrap name and time in a extra div
            */}
            {displaySenderName && (
                <>
                    <div style={userAndTimeWrapperStyle}>
                        <p style={userNameStyle}>{user.displayName} </p>
                        <p style={messageTimeStyle}> {message.createdAt}</p>
                    </div>
                    {message.representative && (
                        <p style={representativeStyle}>{message.representative}</p>
                    )}
                </>
            )}
            {/* otherwise just display time (right aligned) for blue bubbles */}
            {!displaySenderName && <p style={messageTimeStyle}> {message.createdAt}</p>}
        </>
    );

    const renderMessage = hasAvatar => {
        // System messages are not displayed in bubbles
        if (message.system) {
            return (
                <div style={systemBubbleStyle}>
                    <p>{message.body}</p>
                </div>
            );
        }

        if (hasAvatar) {
            return (
                <div style={{ display: 'flex' }}>
                    {user.imageUrl ? (
                        <CustomAvatar src={user.imageUrl} onClick={avatarClick} noborder={1} />
                    ) : (
                        <CustomAvatar color={user.color} onClick={avatarClick}>
                            {user.initials}
                        </CustomAvatar>
                    )}
                    <div
                        style={{ ...chatBubbleStyles, marginTop: 0, marginLeft: '8px' }}
                        id={message._id}
                    >
                        {messageBody()}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ ...chatBubbleStyles }} id={message._id}>
                    {messageBody()}
                </div>
            );
        }
    };

    return (
        <div
            style={{ ...styles.chatbubbleWrapper }}
            onMouseEnter={() => setMessageHovered(message.id === 0 ? message._id : null)}
            onMouseLeave={() => setMessageHovered(null)}
        >
            {message.id === 0 && message._id === messageHovered && !message.removedFromUser && (
                <DeleteMessageButton
                    message={message}
                    chatRoomId={chatRoomId}
                    onButtonClick={() =>
                        setMessageToDelete(
                            {
                                ...message,
                                txt: message.body,
                                id: message._id,
                                timestamp: message._timestamp,
                            },
                            chatRoomId,
                        )
                    }
                />
            )}
            {renderMessage(showAvatar)}
        </div>
    );
};

export default connect(null, {
    setMessageToDelete: onSetMessageToDelete,
})(ChatBubble);
