import React, { useMemo, useState, useEffect } from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import AnalyticsService from '../../../../features/analytics/services';
import { submitPollAnswer } from '../../../../services/api/eureka';
import VotingInProgress from './VotingInProgress';
import VotingResults from './VotingResults';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';

const Voting = props => {
    const { pollSets, stateCtx, activePoll, showVoteResults, isBeamerView } = props;
    const votes = get(pollSets, 'votes', {});
    const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
    const isPollSetOpen = pollSets?.state === 'open';
    const options = get(activePoll, 'PollOptions', []);
    const optionsWithVotes = options
        .filter(option => option.text.length > 0)
        .sort((o1, o2) => o1.order - o2.order)
        .map(option => ({
            ...option,
            votes: pollSets.votes[option.id],
        }));
    const totalVotes = optionsWithVotes.reduce((acc, option) => acc + option.votes, 0);
    const sessionId = props.match.params.objectId || props.match.params.timeslotId;
    const currentPollAnswer =
        activePoll && answeredPolls.find(poll => poll.pollId === activePoll.id);
    const [isPollAnswered, setIsPollAnswered] = useState(!!currentPollAnswer);
    const [selectedOptionIds, setSelectedOptionIds] = useState([]);

    const { trackAchievement } = useAchievementActions();

    const mostLikedOptions = useMemo(() => {
        if (!activePoll || !options.length) {
            return [];
        }

        const optionsIdsWithVotes = options.map(option => ({
            id: option.id,
            votes: votes[option.id],
        }));
        const maxNumberOfVotes = sortBy(optionsIdsWithVotes, 'votes').reverse()[0].votes;

        // Do not trigger 'vote-most-voted-option' achievement if there are no votes
        if (maxNumberOfVotes === 0) {
            return [];
        }

        return optionsIdsWithVotes
            .filter(option => option.votes === maxNumberOfVotes)
            .map(option => option.id);
    }, [activePoll, votes]);

    const leastLikedOptions = useMemo(() => {
        if (!activePoll || !options.length) {
            return [];
        }

        const optionsIdsWithVotes = options.map(option => ({
            id: option.id,
            votes: votes[option.id],
        }));
        const minNumberOfVotes = sortBy(optionsIdsWithVotes, 'votes')[0].votes;
        const hasVotes = optionsIdsWithVotes.findIndex(option => option.votes > 0) > -1;

        // Do not trigger 'vote-least-voted-option' achievement if there are no votes
        if (!hasVotes) {
            return [];
        }

        return optionsIdsWithVotes
            .filter(option => option.votes === minNumberOfVotes)
            .map(option => option.id);
    }, [activePoll, votes]);

    const trackVoteAchievements = optionId => {
        trackAchievement(AchievementType.VOTE, activePoll.id);

        if (mostLikedOptions.includes(optionId)) {
            trackAchievement(AchievementType.VOTE_MOST_VOTED_OPTION, activePoll.id);
        }

        if (leastLikedOptions.includes(optionId)) {
            trackAchievement(AchievementType.VOTE_LEAST_VOTED_OPTION, activePoll.id);
        }
    };

    const submitVote = async optionIds => {
        const checkedOptions = options.filter(option => optionIds.find(id => id === option.id));

        if (!checkedOptions || checkedOptions.length === 0) {
            return;
        }

        checkedOptions.forEach(checkedOption => {
            AnalyticsService.addSample(
                'vote',
                JSON.stringify({
                    text: checkedOption.text,
                    votingQuestionId: activePoll.id,
                    questionText: activePoll.title,
                }),
                sessionId,
            );
            trackVoteAchievements(checkedOption.id);
        });

        const ids = checkedOptions.map(option => option.id);
        await submitPollAnswer({ pollOptionIds: ids });
        setIsPollAnswered(true);

        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        answeredPolls.push({ pollId: activePoll.id, optionIds: ids });
        localStorage.setItem('answeredPolls', JSON.stringify(answeredPolls));

        const { socket } = stateCtx;

        if (socket) {
            socket.emit('pollAnswer', { objectId: sessionId });
        }
    };

    useEffect(() => {
        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        const currentPollAnswer = answeredPolls.find(poll => poll.pollId === activePoll.id);

        setIsPollAnswered(!!currentPollAnswer);
    }, [activePoll.id]);

    if (!isPollSetOpen && !showVoteResults) {
        return (
            <VotingInProgress
                activePoll={activePoll}
                totalVotes={totalVotes}
                isBeamerView={isBeamerView}
            />
        );
    }

    if (isPollSetOpen && !isPollAnswered) {
        return (
            <VotingInProgress
                pollSet={pollSets}
                activePoll={activePoll}
                votingIsActive
                totalVotes={totalVotes}
                onSubmitVote={submitVote}
                isBeamerView={isBeamerView}
                selectedOptionIds={selectedOptionIds}
                setSelectedOptionIds={setSelectedOptionIds}
            />
        );
    }

    if ((isPollSetOpen && isPollAnswered) || showVoteResults) {
        return (
            <VotingResults
                activePoll={activePoll}
                pollSet={pollSets}
                optionsWithVotes={optionsWithVotes}
                totalVotes={totalVotes}
                showVoteResults={showVoteResults}
                currentPollAnswer={currentPollAnswer}
                isBeamerView={isBeamerView}
            />
        );
    }
};

export default Voting;
