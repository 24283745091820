import React from 'react';
import { withRouter } from 'react-router-dom';

import DefaultChatBubble from '../ChatBubble';
import AppointmentCard from '../Appointment';
import { navigateTo } from '../../Navigation/NavigationLink';

const styles = {
    chatbubbleWrapper: {
        marginTop: 10,
        marginBottom: 10,
        overflow: 'auto',
        position: 'relative',
    },
    bubbleGroupHeader: {
        marginLeft: 30,
        fontSize: 14,
        fontWeight: '400',
        color: '#999',
    },
    dateSystemBubble: {
        marginTop: 8,
        marginRight: 'auto',
        marginBottom: 8,
        marginLeft: 'auto',
        maxWidth: 300,
        paddingLeft: 14,
        paddingRight: 14,
        width: '-webkit-fit-content',
    },
    dateSystemText: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
    },
};

const BubbleGroup = props => {
    const { match, history, location, isPresentation } = props;
    /**
     * Parses and collects messages of one type to be grouped together.
     * @return {messageNodes} - a JSX wrapped group of messages
     */
    const DateMessage = msg => {
        return (
            <div style={styles.dateSystemBubble}>
                <p style={styles.dateSystemText}>{`${msg}`}</p>
            </div>
        );
    };
    const renderGroup = messages => {
        const { chatBubble, showSenderName, chatRoomId } = props;
        const ChatBubble = chatBubble || DefaultChatBubble;
        const sampleMessage = messages[0];
        const messageNodes = messages.map((message, index) => {
            if (message.appointment) {
                return (
                    <React.Fragment key={`appointment_${message._id}_${index}`}>
                        {message.dateMessage && DateMessage(message.dateMessage)}
                        <ChatBubble
                            message={message}
                            showAvatar={sampleMessage.id !== 0 && index === 0}
                            avatarClick={() => onUserClick(message.user.id)}
                            showSenderName={showSenderName}
                            chatRoomId={chatRoomId}
                        />
                        <AppointmentCard
                            key={index}
                            message={message}
                            data={message.appointment}
                            goToAppointmentDetails={goToAppointmentDetails}
                        />
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment key={`chat_${message._id}_${index}`}>
                    {message.dateMessage && DateMessage(message.dateMessage)}
                    <ChatBubble
                        message={message}
                        showAvatar={sampleMessage.id !== 0 && index === 0}
                        avatarClick={() => onUserClick(message.user.id)}
                        showSenderName={showSenderName}
                        chatRoomId={chatRoomId}
                        isPresentation={isPresentation}
                    />
                </React.Fragment>
            );
        });

        return <div style={styles.chatbubbleWrapper}>{messageNodes}</div>;
    };

    const onUserClick = id => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId: id,
            },
        });
    };

    const goToAppointmentDetails = id => {
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'appointment',
                objectId: id,
            },
        });
    };

    const { messages } = props;

    return renderGroup(messages);
};

export default withRouter(BubbleGroup);
