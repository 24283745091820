import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import { darken } from '@material-ui/core';
import { useTheme } from '../../components/Theme/ThemeContext';
import { StyledDropdownMenu, StyledListItem } from '../Programme/style/style';
import { Flex } from './Flex';
import * as palette from '../../components/General/Variables';
import useOutsideListener from '../../hooks/useOutsideListener';

const ButtonContainer = styled.button`
    width: ${props => props.width} !important;
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: ${props => (props.enabled ? 'pointer' : 'default')};
    position: relative;
    background: transparent;
    border: none;
`;

const Dropdown = styled(StyledDropdownMenu)`
    margin-left: ${props => props.autoMarginLeft && 'auto'};
    margin-right: 0;
    float: right;
    //width: 100% !important;

    .md-list {
        ${props => (props.left ? `left: ${props.left}` : 'right: 30px')};
        top: ${props => props.top};
        margin-top: ${props => props.customMarginTop || '45px'} !important;
        width: ${props => props.width} !important;
        height: unset !important;
        padding: 4px !important;
    }
`;

const DropdownItem = styled(StyledListItem)`
    .md-list-tile {
        height: 48px !important;
        border: 4px !important;
        text-align: left;

        &:not(:last-of-type) {
            margin-bottom: 4px;
        }
    }

    .md-tile-content--left-icon {
        padding-left: 19px !important;
    }

    .md-text--theme-primary {
        text-transform: lowercase !important;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.87)')} !important;
    }

    .md-text--theme-primary:first-letter {
        text-transform: uppercase !important;
    }

    .md-text {
        text-transform: lowercase !important;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.87)')} !important;
    }

    .md-text:first-letter {
        text-transform: uppercase !important;
    }

    i {
        color: ${props => (props.active ? props.primary : 'transparent')} !important;
    }

    ${props =>
        props.dropdownIcons &&
        `
                 .md-text--theme-primary {
                    color: rgba(0, 0, 0, 0.87) !important;
                }
            
                .md-text {
                    color: rgba(0, 0, 0, 0.87) !important;
                }
            
                i {
                    color: rgba(0, 0, 0, 0.87) !important;
                }
            `};
`;

const DropdownIconContainer = styled(Flex)`
    float: right;
    width: 32px !important;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-left: ${props => props.autoMarginLeft && 'auto'};
    border-left: 1px solid rgba(255, 255, 255, 0.6);

    i {
        color: ${palette.COLOR_WHITE};
    }
`;

const GreenButtonContainer = styled(ButtonContainer)`
    background-color: ${props => (props.background ? props.background : '#00a933')};
    width: fit-content;

    &:hover {
        background-color: ${props =>
            props.background ? darken(props.background, 0.2) : darken('#00a933', 0.2)};
    }

    &:active {
        background-color: ${props =>
            props.background ? darken(props.background, 0.4) : darken('#00a933', 0.2)};
    }
`;

const GreenButtonReverseContainer = styled(ButtonContainer)`
    background-color: ${props => (props.background ? props.background + '30' : '#00a933' + '30')};
    width: fit-content;
    ${props => props.background && `color: ${props.background} !important`};

    &:hover {
        background-color: ${props =>
            props.background ? props.background + '30' : '#00a933' + '30'};
        filter: brightness(90%);
    }

    &:active {
        background-color: ${props =>
            props.background ? props.background + '30' : '#00a933' + '30'};
        filter: brightness(70%);
    }
`;

const GhostButtonContainer = styled(ButtonContainer)`
    background: transparent;
    width: fit-content;
`;

const GhostLongButtonContainer = styled(GhostButtonContainer)`
    width: 100%;
`;

const GreenLongReverseButtonContainer = styled(GreenButtonReverseContainer)`
    width: 100%;
`;

const GreenLongButtonContainer = styled(GreenButtonContainer)`
    width: 100%;
`;

const GreyButtonContainer = styled(ButtonContainer)`
    background-color: ${props =>
        props.enabled ? 'rgba(239, 239, 239, 1)' : 'rgba(0, 0, 0, 0.38)'};
    opacity: 1;
    width: fit-content;
    ${props =>
        props.enabled &&
        !props.showDropdwn &&
        `
        &:hover {
            background-color: gba(239, 239, 239, 0.4);
            filter: brightness(90%);
        }
        &:active {
            background-color: gba(239, 239, 239, 0.12);
            filter: brightness(70%);
        }
    `};
`;

const GreyLongButtonContainer = styled(GreyButtonContainer)`
    width: 100%;
`;

const ButtonIcon = styled(FontIcon)`
    margin-right: ${props => (props.marginRight ? 8 : 0)}px;
    color: ${props => (!props.enabled ? '#fff' : props.iconCustomColor || '#000000')} !important;
    font-size: 24px !important;
    ${props => props.enabled && 'cursor: pointer'}
`;

const ButtonIconLight = styled(ButtonIcon)`
    color: #ffffff !important;
`;

const ButtonText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: ${props =>
        props.customTextColor ? props.customTextColor : props.enabled ? '#000000' : '#fff'};
    text-align: center;
`;

const ButtonTextLight = styled(ButtonText)`
    color: #ffffff;
`;

const ButtonIconLightReverse = styled(ButtonIcon)`
    color: ${props => props.background || '#00a933'};
`;

const ButtonTextLightReverse = styled(ButtonText)`
    color: ${props => props.background || '#00a933'};
`;

export const Tooltip = styled.div`
    width: fit-content;
    padding: 4px 8px;
    height: 28px;
    background: ${props => props.background || 'rgba(0, 0, 0, 0.87)'};
    border-radius: 4px;
    position: absolute;
    bottom: ${props => (props.bottom ? props.bottom : '48px')};
    ${props => props.left && `left: ${props.left}`};
    ${props => props.top && `top: ${props.top}`};
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    font-stretch: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 21;
    white-space: nowrap;
`;

export const buttonTypes = {
    GREEN: 'green',
    GREEN_LONG: 'green-long',
    GREEN_REVERSE: 'green-reverse',
    GREEN_LONG_REVERSE: 'green-long-reverse',
    GREY: 'grey',
    GREY_LONG: 'grey-long',
    GHOST: 'ghost',
    GHOST_LONG: 'ghost-long',
};

const Button = ({
    type,
    text,
    icon,
    outlinedIcon = false,
    onClick,
    background,
    enabled = true,
    style,
    children,
    image,
    imageStyle,
    chip,
    alt,
    'data-qa': dataQa,
    iconCustomColor,
    customTextColor,
    tooltip,
    dropdown,
    dropdownOptions,
    activeOptionDropdown,
    setActiveOptionDropdown,
    disabledOptions,
    openDropdownFromButton,
    dropdownIcons,
    dropdownActions,
    displayedInBooth,
    tabIndex = 0,
}) => {
    const [hover, setHover] = useState(false);
    const [showDropdwn, setShowDropdwn] = useState(false);
    const [dropdownTop, setDropdwnTop] = useState(0);
    const buttonContainerRef = useRef(null);
    const getContainer = type => {
        switch (type) {
            case buttonTypes.GREEN:
                return GreenButtonContainer;
            case buttonTypes.GREEN_LONG:
                return GreenLongButtonContainer;
            case buttonTypes.GREY:
                return GreyButtonContainer;
            case buttonTypes.GREY_LONG:
                return GreyLongButtonContainer;
            case buttonTypes.GREEN_REVERSE:
                return GreenButtonReverseContainer;
            case buttonTypes.GREEN_LONG_REVERSE:
                return GreenLongReverseButtonContainer;
            case buttonTypes.GHOST:
                return GhostButtonContainer;
            case buttonTypes.GHOST_LONG:
                return GhostLongButtonContainer;
            default:
                return ButtonContainer;
        }
    };

    const getIcon = type => {
        switch (type) {
            case buttonTypes.GREEN:
            case buttonTypes.GREEN_LONG:
                return ButtonIconLight;
            case buttonTypes.GREY:
            case buttonTypes.GREEN_REVERSE:
                return ButtonIconLightReverse;
            case buttonTypes.GREEN_LONG_REVERSE:
                return ButtonIconLightReverse;
            default:
                return ButtonIcon;
        }
    };

    const getText = type => {
        switch (type) {
            case buttonTypes.GREEN_REVERSE:
                return ButtonTextLightReverse;
            case buttonTypes.GREEN_LONG_REVERSE:
                return ButtonTextLightReverse;
            case buttonTypes.GHOST:
                return ButtonTextLightReverse;
            case buttonTypes.GHOST_LONG:
                return ButtonTextLightReverse;
            case buttonTypes.GREEN:
            case buttonTypes.GREEN_LONG:
                return ButtonTextLight;
            case buttonTypes.GREY:
            default:
                return ButtonText;
        }
    };

    useEffect(() => {
        const scrollable = document.getElementById('scrollable');
        if (scrollable && dropdown) {
            scrollable.addEventListener('scroll', handleScrolling);
        }
        return () => {
            scrollable && scrollable.removeEventListener('scroll', handleScrolling);
        };
    }, []);

    useOutsideListener({
        ref: buttonContainerRef,
        onClickOutside: () => {
            setShowDropdwn(false);
        },
    });

    const handleScrolling = () => {
        if (dropdown) {
            setDropdwnTop(buttonContainerRef.current?.getBoundingClientRect().top);
        }
    };

    const Container = getContainer(type);
    const Icon = getIcon(type);
    const Text = getText(type);
    const { theme } = useTheme();

    let options = [];
    if (dropdown && dropdownOptions?.length) {
        options = dropdownOptions?.map((item, index) => (
            <DropdownItem
                key={`open-virtual-session-menu-item-${index}`}
                primaryText={item}
                primary={theme.primary}
                leftIcon={
                    dropdownIcons &&
                    dropdownIcons[index] !== 'unset' && (
                        <FontIcon iconClassName="material-icons-outlined">
                            {dropdownIcons && dropdownIcons[index]}
                        </FontIcon>
                    )
                }
                dropdownIcons={!!dropdownIcons}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (disabledOptions && disabledOptions?.includes(item)) {
                        return false;
                    } else {
                        if (dropdownActions && dropdownActions[index]) {
                            const action = dropdownActions[index];
                            if (typeof action === 'function') {
                                action(e);
                            }
                            setShowDropdwn(!showDropdwn);
                        } else if (setActiveOptionDropdown) {
                            setActiveOptionDropdown(item);
                        }
                    }
                }}
                active={item === activeOptionDropdown}
                disabled={disabledOptions && disabledOptions?.includes(item)}
            />
        ));
    }

    const ButtonContent = () => (
        <ButtonContainer width={!!chip && '100%'} enabled={enabled} tabIndex={-1}>
            {icon && (
                <Icon
                    marginRight={!!text}
                    background={background}
                    iconCustomColor={iconCustomColor}
                    enabled={enabled}
                    iconClassName={`material-icons${outlinedIcon ? '-outlined' : ''}`}
                >
                    {icon}
                </Icon>
            )}
            {image && <img src={image} alt={alt} className="md-btn" style={imageStyle}></img>}
            <Text
                background={background}
                enabled={enabled}
                customTextColor={customTextColor}
                style={{ cursor: enabled ? 'pointer' : 'default' }}
            >
                {text}
            </Text>
            {chip}
        </ButtonContainer>
    );

    const dropdownLeft = displayedInBooth
        ? `${buttonContainerRef.current?.getBoundingClientRect().left - 145}px`
        : !openDropdownFromButton &&
          `${buttonContainerRef.current?.getBoundingClientRect().left}px`;

    return (
        <Container
            innerRef={buttonContainerRef}
            tabIndex={tabIndex}
            background={background || theme.primary}
            enabled={enabled}
            showDropdwn={showDropdwn}
            style={style}
            onClick={e => {
                if (enabled && onClick) {
                    if (e) {
                        if (openDropdownFromButton) {
                            setShowDropdwn(!showDropdwn);
                        } else if (e.target && e.target.className?.indexOf('disabled') === -1) {
                            onClick(e);
                        }
                    } else onClick();
                }
            }}
            data-qa={dataQa}
            onMouseEnter={() => {
                if (tooltip) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => setHover(false)}
        >
            {!(showDropdwn && openDropdownFromButton) && <ButtonContent />}
            {dropdown && (
                <>
                    {showDropdwn ? (
                        <Dropdown
                            id="open-virtual-session-menu"
                            width={
                                openDropdownFromButton
                                    ? '250px'
                                    : `${buttonContainerRef.current?.offsetWidth}px`
                            }
                            left={dropdownLeft}
                            menuItems={options}
                            animationPosition="below"
                            simplifiedMenu={false}
                            visible={true}
                            light={false}
                            autoMarginLeft={!chip}
                            customMarginTop={openDropdownFromButton && '8px'}
                            top={`${
                                dropdownTop ||
                                buttonContainerRef.current?.getBoundingClientRect().top
                            }px`}
                        >
                            {!openDropdownFromButton ? (
                                <DropdownIconContainer
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowDropdwn(!showDropdwn);
                                    }}
                                >
                                    <FontIcon>arrow_drop_down</FontIcon>
                                </DropdownIconContainer>
                            ) : (
                                <ButtonContent />
                            )}
                        </Dropdown>
                    ) : (
                        <>
                            {!openDropdownFromButton && (
                                <DropdownIconContainer
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowDropdwn(!showDropdwn);
                                    }}
                                    autoMarginLeft={!chip}
                                >
                                    <FontIcon>arrow_drop_down</FontIcon>
                                </DropdownIconContainer>
                            )}
                        </>
                    )}
                </>
            )}
            {children}
            {tooltip && hover && <Tooltip>{tooltip}</Tooltip>}
        </Container>
    );
};

export default Button;
