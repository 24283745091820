/* eslint-disable no-empty */
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import emojiRegex from 'emoji-regex';

import { getString } from '../../../services/api/store';
import { APPOINTMENT_STATE, APPOINTMENT_PARTICIPANT_STATUS, MESSAGE_TYPE } from '../constants';
import { getNickAsInt } from '../actions';

// helpers
const removedMessageText = getString('groupChatDeleteMessageRemoved') || 'Message removed';
const regex = emojiRegex();

export const getAppointmentState = appointment => {
    let state = '';
    switch (appointment.status) {
        case APPOINTMENT_PARTICIPANT_STATUS.WAITING:
            state = getString('appointmentStatusWaiting');
            break;
        case APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED:
            state = getString('appointmentStatusAccepted');
            break;
        case APPOINTMENT_PARTICIPANT_STATUS.DECLINED:
            state = getString('appointmentStatusDeclined');
            break;

        default:
            break;
    }

    return state;
};

export const getAppointmentMessageText = textObj => {
    const appointment = textObj.data;

    let state = '';
    switch (appointment.action) {
        case APPOINTMENT_STATE.SCHEDULED:
            state = getString('appointmentScheduledNotification');
            break;
        case APPOINTMENT_STATE.ACCEPTED:
            state = getString('appointmentAcceptedNotification');
            break;
        case APPOINTMENT_STATE.DECLINED:
            state = getString('appointmentDeclinedNotification');
            break;
        case APPOINTMENT_STATE.DELETED:
            state = getString('appointmentDeletedNotification');
            break;
        case APPOINTMENT_STATE.UPDATED:
            state = getString('appointmentUpdatedNotification');
            break;

        default:
            state = getString('appointmentScheduledNotification');
            break;
    }

    return state;
};

const oneEmojiOnlyMessage = text => {
    if (!text) {
        return false;
    }

    const textOnly = text.replace(/\s/g, '');
    const emojiOnly = textOnly.replace(regex, '') === '';
    if (emojiOnly) {
        let emojiTotal = 0;
        while (regex.exec(text)) {
            emojiTotal++;
        }
        return emojiTotal === 1 ? true : false;
    }
    return false;
};

const parseMessage = ({ messageId, state, representatives }) => {
    const message = state.talk.messages[messageId];
    const currentUser = state.talk.user;

    if (!currentUser || !currentUser.firstName) {
        console.log(' >>>>>>>>>>>>>>>>>>>>>>>>>>>>> chat error, no currentUser on store');
        return null;
    }

    const messageUserId = getNickAsInt(message.nick);
    const userIsCurrentUser = messageUserId === currentUser.id;

    let participant = state.talk.participants[messageUserId];
    if (userIsCurrentUser) {
        participant = {
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            displayName: `${currentUser.firstName} ${currentUser.lastName}`,
            imageUrl: currentUser.imageUrl,
            color: currentUser.color,
        };
    }

    if (!participant) {
        return null;
    }

    if (!participant.displayName) {
        participant.displayName = `${participant.firstName} ${participant.lastName}`;
    }

    let representativeTitle = null;
    if (representatives) {
        const represents = representatives.find(r => r.id === messageUserId);
        representativeTitle = represents ? represents.title : null;
    }

    const initials = `${participant.firstName[0]}${participant.lastName[0]}`.toUpperCase();
    const bigMessage = oneEmojiOnlyMessage(message.txt);

    const messageObj = {
        _id: message.id,
        text: message.txt,
        system: message.removedFromUser,
        removedFromUser: message.removedFromUser,
        removedFromModerator: message.removedFromModerator,
        createdAt: new Date(message.created_at),
        timestamp: message.timestamp,
        user: {
            ...participant,
            _id: participant.id,
            initials,
        },
        big: bigMessage,
        nick: message.nick,
        representativeTitle,
    };

    // check if message is something else than plain text like appointment for example
    try {
        const textObj = JSON.parse(message.txt);
        if (textObj.type === MESSAGE_TYPE.APPOINTMENT) {
            let text = getAppointmentMessageText(textObj);
            if (messageUserId === currentUser.id) {
                text = `You ${text}`;
            }
            messageObj.text = text;

            const { start, end, name, description } = textObj.data;

            const needsResponse =
                textObj.data.action === APPOINTMENT_STATE.SCHEDULED &&
                currentUser.id !== textObj.data.creator;

            // TODO: update this when multiple participants are supported
            // update implementation in order to get all participants information.. probably this will
            // happen on NEW_MESSAGE action (a call to getEurekaInfoWithJids)
            const participantIds = [
                ...new Set([
                    textObj.data.creator,
                    textObj.data.to,
                    currentUser.id,
                    textObj.data.UserId,
                ]),
            ].filter(id => id);

            // update participants data model to match PersonListItem's component props, so we
            // don't have to manipulate the data in any way in the component, just to display it.
            const participants = participantIds.map(pId => {
                const isCurrentUser = pId === currentUser.id;
                const isCreator = pId === textObj.data.creator;

                const participantData = isCurrentUser ? currentUser : state.talk.participants[pId];

                let subNameList = '';
                if (isCreator) {
                    subNameList = getString('appointmentOrganiser');
                } else {
                    subNameList = getAppointmentState(textObj.data);
                }
                return {
                    ...participantData,
                    subNameList,
                    name: participantData
                        ? participantData.displayName ||
                          `${participantData.firstName} ${participantData.lastName}`
                        : '',
                };
            });

            const minutesDuration = Math.floor((end - start) / 60);
            const hoursDuration = parseInt(minutesDuration / 60, 10);
            const duration =
                minutesDuration < 60
                    ? `${minutesDuration}m`
                    : `${hoursDuration}h${
                          minutesDuration / 60 !== 1
                              ? ` ${minutesDuration - hoursDuration * 60}m`
                              : ''
                      }`;

            const appointment = {
                ...textObj.data,
                title: name,
                subNameDetail: description,
                start,
                end,
                duration,
                participants,
                needsResponse,
            };

            messageObj.quickReplies = {
                ...textObj,
                data: appointment,
            };
        }
    } catch {}

    return messageObj;
};

// selectors

export const getMessageToDelete = state => state.talk.messageToDelete;
export const getShowDeleteMessageModal = state => state.talk.showDeleteMessageModal;

export const getGroupChatMessages = (state, conversationId) => {
    const conversation = state.talk.groupConversations[conversationId];
    console.log(' >>>>>>>>>>>>>>>>>>>>>>>>>>>>> conversation', conversationId);

    if (!conversation) {
        return [];
    }

    const messageIds = get(conversation, 'messages', []);
    const representatives = get(conversation, 'representatives', []);
    if (messageIds.length) {
        const messages = [];
        messageIds.forEach(messageId => {
            const message = parseMessage({ messageId, state, representatives });
            if (message) {
                messages.push(message);
            }
        });
        return messages.sort((m1, m2) => m2.timestamp - m1.timestamp);
    } else {
        return [];
    }
};

export const getPrivateMessagesHistory = (state, conversationId) => {
    const conversation = state.talk.privateConversations[conversationId];

    if (!conversation) {
        return [];
    }

    const messageIds = get(conversation, 'messages', []);

    const messages = [];

    messageIds.forEach(messageId => {
        const message = parseMessage({ messageId, state });
        // Sometimes lastMessage gets duplicated with what's coming from request
        const found = messages.find(m => m._id === messageId);
        // const isRemoved = message && message.text === '__removed__';
        if (message && !found) {
            messages.push(message);
        }
    });

    return orderBy(messages, ['timestamp'], ['desc']);
};

export const getIsLoadingChatMessages = (state, conversationId, privateChat = true) =>
    get(
        state,
        `talk.api.${conversationId}.${
            privateChat ? 'isLoadingPrivateChatMessages' : 'isLoadingGroupChatMessages'
        }`,
        false,
    );

export const getChatHasMoreMessages = (state, conversationId) =>
    get(state, `talk.api.${conversationId}.hasMore`, true);

export const getIsLoadingGroupChatParticipants = (state, conversationId) =>
    get(state, `talk.api.${conversationId}.isLoadingGroupChatParticipants`, false);

export const getLoadChatMessagesError = (state, conversationId, privateChat) =>
    get(
        state,
        `talk.api.${conversationId}.${
            privateChat ? 'loadPrivateChatMessagesError' : 'loadGroupChatMessagesError'
        }`,
        null,
    );
